<i18n>
    [
        "login__unauthModal--closetTitle",
        "login__unauthModal--closetSubtitle",
    ]
</i18n>

<template>
    <JoinModal
        :header="$t('login__unauthModal--closetTitle')"
        :subheader="$t('login__unauthModal--closetSubtitle')"
        :illustration="IllustrationItem"
    />
</template>

<script>

import JoinModal from '~coreRoutes/join/components/JoinModal.vue';

import IllustrationItem from '~coreImages/illustration__item.png';

export default {
    name: 'ClosetJoinModal',
    components: {
        JoinModal,
    },
    props: {},
    data: () => ({
        IllustrationItem,
    }),
};
</script>

<style lang="scss">
</style>
